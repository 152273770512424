import { graphql, navigate } from 'gatsby';
import React from 'react';
import LoadQuoteWrapper from 'components/ApiRequestWrapper/LoadQuoteWrapper';
import SecurePaymentFooter from 'components/Footer/SecurePaymentFooter';
import Layout from 'components/Layout';
import AnnualPaymentPage from 'components/Payment/AnnualPaymentPage';
import MonthlyPaymentPage from 'components/Payment/MonthlyPaymentPage';
import { QuoteAndBuyStep } from 'components/ProgressBar';
import { useCheckoutTracking } from 'helpers/ecommerceTracking';
import { PageTitle } from 'helpers/eventTracking';
import { TescoPageViewEventLevel } from 'helpers/eventTrackingForTesco';
import { usePageTracking } from 'helpers/pageTracking';
import {
  useResetTescoPageLoadTrackingOnRefresh,
  useTescoPageLoadTracking,
  useTescoPageViewTracking,
} from 'helpers/pageTrackingForTesco';
import { isQuoteSummaryDetailsInvalid } from 'helpers/quoteAndBuyPageFlowHelpers';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { CsHero } from 'types/contentStack';
import { DividerWithMargin } from './styles';

const STEP = 5;

type PaymentProps = {
  data: {
    csPetPayment: {
      meta_title: string;
      hero: CsHero;
    };
  };
  location: Location & { state?: { detailsValid: boolean } };
};

export const query = graphql`
  query {
    csPetPayment {
      meta_title
      hero {
        heading
        subheading
      }
    }
  }
`;

const Payment: React.FC<PaymentProps> = ({
  data: {
    csPetPayment: { hero, meta_title },
  },
  location: { state },
}) => {
  const quote = useCurrentQuote();

  usePageTracking(meta_title, !!quote.customerInfo);
  useTescoPageLoadTracking();
  useResetTescoPageLoadTrackingOnRefresh();
  useTescoPageViewTracking(TescoPageViewEventLevel.extended);
  useCheckoutTracking(STEP, quote, true);

  if (quote.petInfos) {
    if (isQuoteSummaryDetailsInvalid(quote.quoteOptions)) {
      navigate(quoteAndBuyRoutes.quoteSummary);
    } else if (!state?.detailsValid) {
      navigate(quoteAndBuyRoutes.checkYourDetails);
    }
  }

  return (
    <LoadQuoteWrapper>
      <Layout
        currentStep={QuoteAndBuyStep.Payment}
        pageTitle={PageTitle.Payment}
        heading={hero.heading}
        subheading={hero.subheading}
        metaTitle={meta_title}>
        {quote.quoteOptions.isAnnualPayment ? (
          <>
            <AnnualPaymentPage />
            <DividerWithMargin />
          </>
        ) : (
          <MonthlyPaymentPage />
        )}
      </Layout>
      {quote.quoteOptions.isAnnualPayment && <SecurePaymentFooter />}
    </LoadQuoteWrapper>
  );
};

export default Payment;
