import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const Heading = styled.h2`
  margin: 0 auto ${spacing(1)} 0;

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(4)};
  `}

  ${fonts.headingMedium};
`;

export const Container = styled.div`
  margin: ${spacing(6)} 0 ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(6)};
  `}
`;

export const StyledGrid = styled(Grid)`
  flex-direction: column-reverse;

  ${mediaQuery.tabletPortrait`
    flex-direction: row;
  `}
`;

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${spacing(3)};
`;

export const HeadingAndLogoGridItem = styled(GridItem)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
