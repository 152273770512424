import { Divider } from '@rsa-digital/evo-shared-components/components/Divider/SimpleDivider/styles';
import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { addYearsToDate } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import {
  ANNUAL_PAYMENT_PSEUDO_URL,
  MONTHLY_PAYMENT_PSEUDO_URL,
  PageTitle,
  trackSwitchPaymentClick,
} from 'helpers/eventTracking';
import { trackPageView } from 'helpers/pageTracking';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import {
  noOpPlaceholderReplacer,
  replacePlaceholdersPlainText,
} from 'helpers/placeholders/replaceCsPlaceholders';
import { formatInPoundsWithSign } from 'helpers/poundsHelpers';
import { CurrentQuote } from 'helpers/useCurrentQuote';
import { useCustomerDetails } from 'state/formData/customerDetails';
import { CsIcon } from 'types/contentStack';
import SectionHeadingWithQuoteNumber from './SectionHeadingWithQuoteNumber';
import {
  BoldParagraphSpan,
  GridItemWithTopMargin,
  LightParagraphSpan,
  ParagraphText,
  RichTextWithMargin,
  StyledGrid,
  StyledInfoCard,
  StyledPricePanel,
} from './styles';

type PaymentSummaryContent = {
  csPetCheckYourDetailsDetailsSection: {
    quote_reference_label: string;
    your_cover_dates: {
      policy_starts: string;
      policy_renewal_date: string;
    };
  };
  csPetSummaryOfCoverInstallmentInfo: {
    annual_installment_text: string;
    monthly_installment_text: string;
    switch_to_annual_link_text: string;
    switch_to_monthly_link_text: string;
    price_panel_copy: string;
  };
  csPetMonthlyPayment: {
    payment_summary: {
      heading: string;
      payment_information: string;
    };
  };
  csPetAnnualPayment: {
    payment_summary: {
      heading: string;
      help_box_text: string;
      payment_information: string;
    };
    pay_monthly_promo: {
      heading: string;
      body: string;
      icon: [CsIcon];
    };
  };
};

const query = graphql`
  query {
    csPetCheckYourDetailsDetailsSection {
      quote_reference_label
      your_cover_dates {
        policy_starts
        policy_renewal_date
      }
    }
    csPetSummaryOfCoverInstallmentInfo {
      annual_installment_text
      monthly_installment_text
      switch_to_annual_link_text
      switch_to_monthly_link_text
      price_panel_copy
    }
    csPetMonthlyPayment {
      payment_summary {
        heading
        payment_information
      }
    }
    csPetAnnualPayment {
      payment_summary {
        heading
        help_box_text
        payment_information
      }
      pay_monthly_promo {
        heading
        body
        icon {
          icon_code
        }
      }
    }
  }
`;

type PaymentSummaryProps = {
  isAnnualPayment: boolean;
  switchPaymentType: () => void;
  quote: CurrentQuote;
};

const PaymentSummarySection: React.FC<PaymentSummaryProps> = ({
  isAnnualPayment,
  switchPaymentType,
  quote,
}) => {
  const {
    csPetCheckYourDetailsDetailsSection,
    csPetSummaryOfCoverInstallmentInfo,
    csPetAnnualPayment,
    csPetMonthlyPayment,
  } = useStaticQuery<PaymentSummaryContent>(query);

  const [customerDetails] = useCustomerDetails();

  const plainTextPlaceholderReplacer = quote
    ? replacePlaceholdersPlainText(quotePlaceholders, quote)
    : noOpPlaceholderReplacer;

  const startDate = new Date(quote.policyInfo?.coverStartDate || '');
  const renewalDate = addYearsToDate(startDate, 1);

  const sectionId = 'payment-summary-heading';

  const payAnnuallyPromoIcon = unwrapSingleton(csPetAnnualPayment.pay_monthly_promo.icon)
    ?.icon_code;

  return (
    <StyledGrid as="section" alignLeft aria-labelledby={sectionId}>
      <GridItem>
        <SectionHeadingWithQuoteNumber
          heading={
            isAnnualPayment
              ? csPetAnnualPayment.payment_summary.heading
              : csPetMonthlyPayment.payment_summary.heading
          }
          quoteNumberLabel={csPetCheckYourDetailsDetailsSection.quote_reference_label}
          quoteNumber={quote.policyInfo?.quoteNumber || ''}
          id={sectionId}
        />
      </GridItem>
      <GridItem desktop={5.4} tabletLandscape={4.5} tabletPortrait={6}>
        <StyledPricePanel
          id="price-panel"
          summaryText={
            isAnnualPayment
              ? csPetSummaryOfCoverInstallmentInfo.annual_installment_text
              : csPetSummaryOfCoverInstallmentInfo.monthly_installment_text
          }
          heading={formatInPoundsWithSign(
            isAnnualPayment
              ? quote.price?.annualPrice.total
              : quote.price?.monthlyPrice.total
          )}
          copy={csPetSummaryOfCoverInstallmentInfo.price_panel_copy}
          link={{
            onClick: () => {
              trackSwitchPaymentClick(
                PageTitle.Payment,
                isAnnualPayment ? 'monthly' : 'annually'
              );
              trackPageView(
                isAnnualPayment ? MONTHLY_PAYMENT_PSEUDO_URL : ANNUAL_PAYMENT_PSEUDO_URL,
                PageTitle.Payment,
                quote,
                customerDetails
              );
              switchPaymentType();
            },
            text: plainTextPlaceholderReplacer(
              isAnnualPayment
                ? csPetSummaryOfCoverInstallmentInfo.switch_to_monthly_link_text
                : csPetSummaryOfCoverInstallmentInfo.switch_to_annual_link_text
            ),
          }}
        />
      </GridItem>
      <GridItem desktop={6} tabletLandscape={6} tabletPortrait={6}>
        {isAnnualPayment && (
          <>
            <StyledInfoCard
              data-cy="annualPaymentSummaryPromo"
              heading={csPetAnnualPayment.pay_monthly_promo.heading}
              body={csPetAnnualPayment.pay_monthly_promo.body}
              headerIcon={payAnnuallyPromoIcon}
            />
            <Divider aria-hidden />
          </>
        )}
        <RichTextWithMargin
          pageTitle={PageTitle.Payment}
          html={plainTextPlaceholderReplacer(
            isAnnualPayment
              ? csPetAnnualPayment.payment_summary.payment_information
              : csPetMonthlyPayment.payment_summary.payment_information
          )}
        />
      </GridItem>
      <GridItem>
        <ParagraphText>
          <LightParagraphSpan>
            {csPetCheckYourDetailsDetailsSection.your_cover_dates.policy_starts}{' '}
          </LightParagraphSpan>
          <BoldParagraphSpan>{startDate.toLocaleDateString('en-GB')}</BoldParagraphSpan>
        </ParagraphText>
      </GridItem>
      <GridItemWithTopMargin>
        <ParagraphText>
          <LightParagraphSpan>
            {csPetCheckYourDetailsDetailsSection.your_cover_dates.policy_renewal_date}{' '}
          </LightParagraphSpan>
          <BoldParagraphSpan>{renewalDate.toLocaleDateString('en-GB')}</BoldParagraphSpan>
        </ParagraphText>
      </GridItemWithTopMargin>
    </StyledGrid>
  );
};

export default PaymentSummarySection;
