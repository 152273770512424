import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import InfoCard from '@rsa-digital/evo-shared-components/components/InfoCard/InfoCard';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { lightFontWeight } from '@rsa-digital/evo-shared-components/theme/Tesco/fonts';
import styled from 'styled-components';
import ConfirmationBox from 'components/ConfirmationBox';
import PanelWithActionLink from 'components/PanelWithActionLink';
import RichTextWithModal from 'components/RichTextWithModal';

export const StyledGrid = styled(Grid)`
  flex-direction: column;
`;

export const StyledConfirmationBox = styled(ConfirmationBox)`
  margin-top: ${spacing(2)};
  padding: ${spacing(3)};
`;

export const RichTextWithMargin = styled(RichTextWithModal)`
  margin: ${spacing(3)} 0 ${spacing(4)};
  p {
    font-weight: ${lightFontWeight};
  }

  ${mediaQuery.tabletLandscape`
    margin: ${spacing(4)} 0 ${spacing(3)};
  `}
`;

export const ParagraphText = styled.p`
  margin: 0;
`;

const ParagraphSpan = styled.span`
  ${fonts.paragraph}
  margin: 0;
`;

export const LightParagraphSpan = styled(ParagraphSpan)`
  & {
    font-weight: ${lightFontWeight};
  }
`;

export const BoldParagraphSpan = styled(ParagraphSpan)`
  & {
    font-weight: bold;
  }
`;

export const GridItemWithTopMargin = styled(GridItem)`
  margin-top: ${spacing(2)};

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(3)};
  `}
`;

export const StyledPricePanel = styled(PanelWithActionLink)`
  align-items: flex-start;
  > * {
    text-align: left;
  }

  button {
    margin: ${spacing(2)} auto 0;
    ${mediaQuery.tabletPortrait`
    margin: ${spacing(2)} 0 0;
  `}
  }
`;

export const StyledInfoCard = styled(InfoCard)`
  background-color: transparent;
  border: none;
  padding: 0;
  p {
    font-weight: ${lightFontWeight};
  }
  div {
    margin: 0;
  }
`;
