import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import { CsIcon } from 'types/contentStack';
import { DividerWithTopMargin, StyledInfoCard } from './styles';

type CsCancellationSignpostProps = {
  csPetPayment: {
    cancellation_signpost: {
      heading: string;
      body: string;
      icon: [CsIcon];
    };
  };
};

const query = graphql`
  query {
    csPetPayment {
      cancellation_signpost {
        heading
        body
        icon {
          icon_code
        }
      }
    }
  }
`;

const CancellationSignpost: React.FC = () => {
  const {
    csPetPayment: { cancellation_signpost },
  } = useStaticQuery<CsCancellationSignpostProps>(query);

  const cancellationSignpostIcon = unwrapSingleton(cancellation_signpost.icon)?.icon_code;

  return (
    <Grid as="section" alignLeft>
      <GridItem desktop={6} tabletLandscape={6} tabletPortrait={6}>
        <DividerWithTopMargin aria-hidden />
        <StyledInfoCard
          data-cy="cancellationSignpost"
          heading={cancellation_signpost.heading}
          body={cancellation_signpost.body}
          headerIcon={cancellationSignpostIcon}
        />
      </GridItem>
    </Grid>
  );
};

export default CancellationSignpost;
