import { FlexibleWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import React from 'react';
import {
  Container,
  Heading,
  HeadingAndLogoGridItem,
  LogoWrapper,
  StyledGrid,
} from './styles';

type SectionHeadingWithIconsProps = {
  heading: string;
} & ComponentProps;

const SectionHeadingWithIcons: React.FC<SectionHeadingWithIconsProps> = ({
  heading,
  children,
  ...props
}) => (
  <Container {...componentProps(props)}>
    <StyledGrid>
      <HeadingAndLogoGridItem>
        <Heading id="card-payment-heading">{heading}</Heading>
        <LogoWrapper>{children}</LogoWrapper>
      </HeadingAndLogoGridItem>
    </StyledGrid>
    <FlexibleWidthDivider aria-hidden />
  </Container>
);

export default SectionHeadingWithIcons;
