import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const Heading = styled.h2`
  margin: 0 0 ${spacing(3)};

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(4)};
  `}

  ${fonts.headingMedium};
`;

export const Container = styled.div`
  margin: ${spacing(6)} 0 ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(6)};
  `}
`;

export const StyledGrid = styled(Grid)`
  flex-direction: column-reverse;

  ${mediaQuery.tabletPortrait`
    flex-direction: row;
  `}
`;

export const QuoteReferenceLabel = styled.p`
  ${fonts.paragraphLarge};
  display: inline;
  word-wrap: break-word;
`;

export const QuoteNumberText = styled(QuoteReferenceLabel)`
  & {
    font-weight: bold;
  }
`;

export const QuoteReferenceGridItem = styled(GridItem)`
  margin-top: ${spacing(-2)};
  margin-bottom: ${spacing(6)};

  ${mediaQuery.tabletPortrait`
    margin-top: 0;
    margin-bottom: auto;
  `}

  ${mediaQuery.tabletLandscape`
    margin-top: auto;
    margin-bottom: ${spacing(4)};
    text-align: right;
  `}
`;
