import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { ComponentProps } from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import DirectDebitLogo from 'assets/directdebit.svg';
import React from 'react';
import Declaration from 'components/Declaration';
import { PageTitle } from 'helpers/eventTracking';
import useDeclarationContent from './content';
import { RichTextWithMargin, StyledImage } from './styles';

export type DeclarationDetails = {
  hasAgreedToDeclaration: boolean | undefined;
};

type DeclarationSectionProps = {
  hasAgreed: boolean | undefined;
  updateHasAgreed: (update: boolean) => void;
  getError: FieldFunction<DeclarationDetails, string | undefined>;
} & ComponentProps;

const DeclarationSection: React.FC<DeclarationSectionProps> = ({
  hasAgreed,
  updateHasAgreed,
  getError,
}) => {
  const content = useDeclarationContent();

  return (
    <Declaration
      id="declaration"
      hasAgreed={hasAgreed}
      updateHasAgreed={updateHasAgreed}
      error={getError('hasAgreedToDeclaration')}
      questionId="hasAgreedToDeclaration"
      questionText={content.questionText}
      pageTitle={PageTitle.Payment}>
      <StyledImage src={DirectDebitLogo} alt="Direct Debit Logo" />
      <Grid alignLeft>
        <GridItem desktop={9} tabletLandscape={9}>
          <RichTextWithMargin pageTitle={PageTitle.Payment} html={content.information} />
        </GridItem>
      </Grid>
    </Declaration>
  );
};

export default DeclarationSection;
