import TextInput from '@rsa-digital/evo-shared-components/components/Form/TextInput';
import styled from 'styled-components';

export const NameInput = styled(TextInput)`
  & input {
    text-transform: capitalize;
    &::placeholder {
      & {
        font-weight: normal !important;
      }
    }
  }
`;
