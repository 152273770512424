import { Divider } from '@rsa-digital/evo-shared-components/components/Divider/SimpleDivider/styles';
import { IconWrapper } from '@rsa-digital/evo-shared-components/components/Icon/styles';
import InfoCard from '@rsa-digital/evo-shared-components/components/InfoCard/InfoCard';
import {
  Heading,
  InfoCardRichText,
} from '@rsa-digital/evo-shared-components/components/InfoCard/InfoCard/styles';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { lightFontWeight } from '@rsa-digital/evo-shared-components/theme/Tesco/fonts';
import styled from 'styled-components';

export const StyledInfoCard = styled(InfoCard)`
  border: None;
  margin: ${spacing(4)} 0 0;
  padding: 0;
  background-color: ${colors.neutral06};

  ${Heading} {
    margin: 0;

    ${fonts.paragraph}

    & {
      font-weight: 700;
    }

    ${IconWrapper} {
      height: ${spacing(3)};
      width: ${spacing(3)};
      margin: ${spacing(0.25)} ${spacing(2)} 0 0;
      flex-basis: ${spacing(3)};

      ${mediaQuery.tabletLandscape`
        margin: 0 ${spacing(1)} 0 0;
      `}
    }
  }

  ${InfoCardRichText} {
    font-weight: ${lightFontWeight};
  }
`;

export const DividerWithTopMargin = styled(Divider)`
  margin-top: ${spacing(4)};
`;
