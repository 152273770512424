import paymentClient from 'apiHelpers/paymentClient';
import { AxiosError } from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { MonthlyPaymentState } from 'components/Payment/MonthlyPaymentPage';
import { BankDetailsSearch } from 'components/Payment/MonthlyPaymentPage/BankPayment/validation';
import { trackAPIError } from 'helpers/eventTracking';
import { RootState } from 'state/createStore';
import { ERROR, ErrorAction, ErrorType } from 'state/error/actions';
import { UPDATE_QUOTE, UpdateQuoteAction } from 'state/quote/quote';
import mapMonthlyConfirmationPolicyMovementDetails from './mappings/mapMonthlyConfirmationPolicyMovementDetails';
import mapPolicyMovementDetails from './mappings/mapPolicyMovementDetails';
import { ValidateDirectDebitRequest } from './paymentRequest';
import { BankDetailReturnCodes } from './paymentResponse';

const useMonthlyPayment = (): {
  startMonthlyPayment: () => Promise<number | undefined>;
  updateMonthlyPaymentSchedule: (date: number) => Promise<number | undefined>;
  validateBankDetails: (
    request: ValidateDirectDebitRequest
  ) => Promise<BankDetailsSearch>;
  confirmMonthlyPayment: (details: MonthlyPaymentState) => Promise<void>;
} => {
  const quote = useSelector((state: RootState) => state.quote);
  const dispatchQuote = useDispatch<Dispatch<UpdateQuoteAction>>();
  const dispatchError = useDispatch<Dispatch<ErrorAction>>();

  const updateQuoteMovementDate = useCallback(
    (movementDate: string) => {
      if (quote) {
        dispatchQuote({
          type: UPDATE_QUOTE,
          quote: {
            ...quote,
            policyInfo: {
              ...quote.policyInfo,
              quoteMovementUpdateDate: movementDate,
            },
          },
        });
      }
    },
    [dispatchQuote, quote]
  );

  const startMonthlyPayment = async (): Promise<number | undefined> => {
    if (quote?.policyInfo.quoteNumber) {
      const request = {
        policyMovement: mapPolicyMovementDetails(quote),
        quoteNumber: quote.policyInfo.quoteNumber,
      };
      const response = await paymentClient.startMonthlyPayment(request);
      updateQuoteMovementDate(response.quoteMovementUpdateDate);
      return response.collectionAmount;
    }
    return undefined;
  };

  const validateBankDetails = async (
    request: ValidateDirectDebitRequest
  ): Promise<BankDetailsSearch> => {
    try {
      const searchResult = await paymentClient.validateDirectDebit(request);
      if (searchResult?.returnCodes.includes(BankDetailReturnCodes.CODE6)) {
        return {
          status: 'SUCCESS',
          data: undefined,
        };
      }
      if (searchResult?.bankName || searchResult?.branchName) {
        return {
          status: 'SUCCESS',
          data: searchResult,
        };
      }
      return {
        status: 'FAILURE',
      };
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      trackAPIError(error);
      dispatchError({
        type: ERROR,
        errorType: ErrorType.MONTHLY_API_ERROR,
      });
      return {
        status: 'FAILURE',
      };
    }
  };

  const updateMonthlyPaymentSchedule = async (
    date: number
  ): Promise<number | undefined> => {
    try {
      if (quote?.policyInfo.quoteNumber) {
        const request = {
          policyMovement: mapPolicyMovementDetails(quote, date),
          quoteNumber: quote.policyInfo.quoteNumber,
        };
        const response = await paymentClient.updateMonthlyPaymentSchedule(request);
        updateQuoteMovementDate(response.quoteMovementUpdateDate);
        return response.collectionAmount;
      }
      return undefined;
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      trackAPIError(error);
      dispatchError({
        type: ERROR,
        errorType: ErrorType.MONTHLY_API_ERROR,
      });
      return undefined;
    }
  };

  const confirmMonthlyPayment = async (details: MonthlyPaymentState): Promise<void> => {
    if (quote?.policyInfo.quoteNumber) {
      await paymentClient.confirmMonthlyPayment({
        policy: mapMonthlyConfirmationPolicyMovementDetails(quote, details),
        quoteNumber: quote.policyInfo.quoteNumber,
      });
    }
  };

  return {
    startMonthlyPayment,
    updateMonthlyPaymentSchedule,
    validateBankDetails,
    confirmMonthlyPayment,
  };
};

export default useMonthlyPayment;
