import React, { useCallback } from 'react';
import CardPayment from 'components/Payment/AnnualPaymentPage/CardPayment';
import { useCurrentQuote, useUpdateQuoteOptions } from 'helpers/useCurrentQuote';
import PaymentSummarySection from '../PaymentSummarySection';

const AnnualPaymentPage: React.FC = () => {
  const quote = useCurrentQuote();
  const updateQuoteOptions = useUpdateQuoteOptions();

  const switchPaymentType = useCallback(() => {
    updateQuoteOptions({
      isAnnualPayment: !quote.quoteOptions.isAnnualPayment,
    });
  }, [quote.quoteOptions.isAnnualPayment, updateQuoteOptions]);

  return (
    <>
      <PaymentSummarySection
        isAnnualPayment
        switchPaymentType={switchPaymentType}
        quote={quote}
      />
      <CardPayment quote={quote} />
    </>
  );
};

export default AnnualPaymentPage;
