import { CoverLevel, ProductId } from 'helpers/businessConstants';
import {
  QuoteCover,
  QuoteCustomerInfo,
  QuotePolicyInfo,
  QuoteRequestPetInfo,
} from './quoteRequest';

export type QuoteResponsePetInfo = QuoteRequestPetInfo & {
  coverLevelRequired: CoverLevel;
  userSelectedCover: boolean;
};

export enum CoverPetType {
  BOTH = 0,
  DOG = 1,
}

type BundleCover = {
  coverCode: string;
  selected: boolean;
  cover: {
    name: string;
    petType: CoverPetType;
  };
};

export type Bundle = {
  compositeName: string;
  coverLevelRequired: string;
  covers: BundleCover[];
  annualPrice: {
    petPrices: number[];
  };
  installments: {
    petPrices: number[];
  }[];
  apr: number;
};

type BundleContainer = {
  code: number;
  bundles: Bundle[];
  selectedBundleIndex: number;
};

type QuotePremium = {
  bundleContainers: BundleContainer[];
  selectedBundleContainerIndex: number;
};

export type Quote = {
  productId: ProductId;
  uuid: string;
  petInfos: QuoteResponsePetInfo[];
  customerInfo: QuoteCustomerInfo;
  policyInfo: QuotePolicyInfo;
  covers: QuoteCover[];
  parentCover: string[];
  recaptcha?: { recaptchaStatus: boolean; recaptchaScore: number };
  premium: QuotePremium;
};
