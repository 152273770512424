import LoadingOverlayV2 from '@rsa-digital/evo-shared-components/components/LoadingOverlayV2';
import useValidation from '@rsa-digital/evo-shared-components/helpers/forms/useValidation';
import { scrollToElement } from '@rsa-digital/evo-shared-components/helpers/scroll';
import { sortCodeValueToString } from '@rsa-digital/evo-shared-components/helpers/sortCodes';
import useMonthlyPayment from 'apiHelpers/payment/useMonthlyPayment';
import { isQuoteOptionSelectionValid } from 'apiHelpers/quote/bundleCoverMapping';
import { AxiosError } from 'axios';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import FormFooter from 'components/FormFooter';
import BankPayment from 'components/Payment/MonthlyPaymentPage/BankPayment';
import { trackPurchaseEvent } from 'helpers/ecommerceTracking';
import useDefaultErrorHandling from 'helpers/errorHandling';
import {
  PageTitle,
  trackAPIError,
  trackFieldError,
  trackTextButtonClick,
} from 'helpers/eventTracking';
import { scrollAndTrackError } from 'helpers/forms';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import { useSaveAndEmailQuote } from 'helpers/saveQuoteHelpers';
import {
  PAYMENT_MODE_PREVIOUSLY_SELECTED_KEY,
  removeSessionData,
  retrieveData,
  storeData,
} from 'helpers/sessionStorageHelpers';
import { useCurrentQuote, useUpdateQuoteOptions } from 'helpers/useCurrentQuote';
import useLoadingState from 'helpers/useLoadingState';
import useResetReduxState from 'helpers/useResetReduxState';
import { initialQuoteOptions } from 'state/formData/quoteOptions';
import { useConfirmationQuote } from 'state/quote/confirmationQuote';
import useBankDetailsRules, { DirectDebitDetails } from './BankPayment/validation';
import DeclarationSection, { DeclarationDetails } from './DeclarationSection';
import useDeclarationRules from './DeclarationSection/validation';
import CancellationSignpost from '../CancellationSignpost';
import PaymentSummarySection from '../PaymentSummarySection';

type MonthlyPaymentPageContent = {
  csPetMonthlyPayment: {
    next_button_text: string;
  };
  csPetGlobalConfig: {
    loading_spinner: {
      email_spinner_text: string;
      switch_payment_spinner_text: string;
      buy_spinner_text: string;
      monthly_payment_spinner_text: string;
      bank_details_spinner_text: string;
    };
  };
};

const query = graphql`
  query {
    csPetMonthlyPayment {
      next_button_text
    }
    csPetGlobalConfig {
      loading_spinner {
        email_spinner_text
        switch_payment_spinner_text
        buy_spinner_text
        monthly_payment_spinner_text
        bank_details_spinner_text
      }
    }
  }
`;

export type MonthlyPaymentState = DirectDebitDetails &
  DeclarationDetails & {
    collectionAmount?: number;
  };

const MonthlyPaymentPage: React.FC = () => {
  const {
    csPetMonthlyPayment: { next_button_text },
    csPetGlobalConfig: {
      loading_spinner: {
        email_spinner_text,
        switch_payment_spinner_text,
        buy_spinner_text,
        monthly_payment_spinner_text,
        bank_details_spinner_text,
      },
    },
  } = useStaticQuery<MonthlyPaymentPageContent>(query);

  const [, updateConfirmationQuote] = useConfirmationQuote();
  const {
    startMonthlyPayment,
    confirmMonthlyPayment,
    validateBankDetails,
  } = useMonthlyPayment();
  const [isSetup, setIsSetup] = useState(false);
  const [switchingFromAnnual, setSwitchingFromAnnual] = useState(false);
  const {
    isLoading: isSettingUpPayment,
    withLoadingState: setupPaymentWithLoading,
  } = useLoadingState();
  const {
    isLoading: isConfirmingPayment,
    withLoadingState: confirmPaymentWithLoading,
  } = useLoadingState();
  const {
    isLoading: isConfirmingBankDetails,
    withLoadingState: validateBankDetailsWithLoadingState,
  } = useLoadingState();

  const defaultErrorHandling = useDefaultErrorHandling();

  const quote = useCurrentQuote();
  const updateQuoteOptions = useUpdateQuoteOptions();
  const quoteOptions = quote?.quoteOptions ?? initialQuoteOptions;

  const {
    saveAndEmailQuote,
    savedQuoteConfirmationModal,
    isSaveInProgress,
  } = useSaveAndEmailQuote(PageTitle.CheckYourDetails);

  const resetReduxState = useResetReduxState();

  const [paymentState, setPaymentState] = useState<MonthlyPaymentState>({
    isAccountInName: undefined,
    accountHolderName: '',
    accountNumber: '',
    accountSortCode: {},
    monthlyPaymentDate: '01',
    bankDetails: { status: 'NONE' },
    paymentError: false,
    hasAgreedToDeclaration: undefined,
  });

  const directDebitRules = useBankDetailsRules();
  const declarationRules = useDeclarationRules();

  const { getError, validateOnSubmit, showValidation } = useValidation(
    paymentState,
    {
      ...directDebitRules,
      ...declarationRules,
    },
    trackFieldError
  );

  const updatePaymentState = useCallback(
    (update: Partial<MonthlyPaymentState>) =>
      setPaymentState((state) => ({
        ...state,
        ...update,
      })),
    [setPaymentState]
  );

  const {
    accountNumber: accountNumberRules,
    accountSortCode: sortCodeRules,
  } = useBankDetailsRules();

  const tryValidateBankDetails = async (): Promise<void> => {
    if (
      paymentState.bankDetails.status === 'NONE' &&
      accountNumberRules?.every((rule) =>
        rule.validityCondition(paymentState.accountNumber, paymentState)
      ) &&
      sortCodeRules?.every((rule) =>
        rule.validityCondition(paymentState.accountSortCode, paymentState)
      )
    ) {
      const request = {
        accountNumber: paymentState.accountNumber,
        sortCode: sortCodeValueToString(paymentState.accountSortCode),
        quoteNumber: quote.policyInfo?.quoteNumber || '',
      };
      const searchResult = await validateBankDetailsWithLoadingState(() =>
        validateBankDetails(request)
      );
      updatePaymentState({
        bankDetails: searchResult,
      });
    }
  };

  const onSubmitAndMoveNext = useCallback(
    async (details: MonthlyPaymentState): Promise<void> => {
      if (paymentState.bankDetails.status === 'FAILURE') {
        scrollToElement('accountNumber-error', 20);
        return;
      }

      if (paymentState.bankDetails.status === 'NONE') {
        scrollToElement('accountNumber-label', 20);
        return;
      }

      if (isConfirmingPayment) {
        return;
      }

      try {
        updatePaymentState({
          paymentError: false,
        });
        await confirmPaymentWithLoading(() => confirmMonthlyPayment(details));
        const confirmationQuote = { ...quote };
        trackPurchaseEvent(quote);
        removeSessionData();
        resetReduxState();

        updateConfirmationQuote(confirmationQuote);
        navigate(quoteAndBuyRoutes.confirmation);
      } catch (err) {
        const error: AxiosError = err as AxiosError;
        trackAPIError(error);
        updatePaymentState({
          paymentError: true,
        });
        scrollToElement('payment-error', 20);
      }
    },
    [
      paymentState.bankDetails.status,
      isConfirmingPayment,
      updatePaymentState,
      confirmPaymentWithLoading,
      quote,
      resetReduxState,
      updateConfirmationQuote,
      confirmMonthlyPayment,
    ]
  );

  useEffect(() => {
    if (quote.petInfos && !isSetup && !isSettingUpPayment) {
      setupPaymentWithLoading(async () => {
        try {
          const response = await startMonthlyPayment();
          updatePaymentState({
            collectionAmount: response,
          });
          setIsSetup(true);
        } catch (err) {
          const error: Error = err as Error;
          defaultErrorHandling(error);
        }
      });
    }
  }, [
    defaultErrorHandling,
    isSettingUpPayment,
    isSetup,
    setupPaymentWithLoading,
    startMonthlyPayment,
    updatePaymentState,
    quote.petInfos,
  ]);

  const switchPaymentType = useCallback(() => {
    updateQuoteOptions({
      isAnnualPayment: !quote.quoteOptions.isAnnualPayment,
    });
  }, [quote.quoteOptions.isAnnualPayment, updateQuoteOptions]);

  useEffect(() => {
    const previousPaymentMode = retrieveData(PAYMENT_MODE_PREVIOUSLY_SELECTED_KEY);
    if (previousPaymentMode === 'ANNUAL') {
      setSwitchingFromAnnual(true);
      storeData(PAYMENT_MODE_PREVIOUSLY_SELECTED_KEY, 'MONTHLY');
    }
  }, []);

  const settingUpPaymentLoadingMessage = switchingFromAnnual
    ? switch_payment_spinner_text
    : monthly_payment_spinner_text;

  useEffect(() => {
    // Make payment and redirect to Confirmation page
    if (paymentState.bankDetails.status === 'SUCCESS') {
      onSubmitAndMoveNext(paymentState);
    }
    if (paymentState.bankDetails.status === 'FAILURE') {
      scrollToElement('accountNumber-error', 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentState.bankDetails.status]);

  return (
    <>
      {isSettingUpPayment && (
        <LoadingOverlayV2
          id="preparing_payment"
          loadingMessage={settingUpPaymentLoadingMessage}
          timeDuration={12}
        />
      )}
      {isConfirmingPayment && (
        <LoadingOverlayV2 loadingMessage={buy_spinner_text} timeDuration={10} />
      )}
      {isSaveInProgress && (
        <LoadingOverlayV2 loadingMessage={email_spinner_text} timeDuration={10} />
      )}
      {isConfirmingBankDetails && (
        <LoadingOverlayV2
          id="confirming_bank_details"
          loadingMessage={bank_details_spinner_text}
          timeDuration={10}
        />
      )}
      <form
        onSubmit={validateOnSubmit(() => {
          tryValidateBankDetails();
        }, scrollAndTrackError)}>
        <PaymentSummarySection
          isAnnualPayment={false}
          switchPaymentType={switchPaymentType}
          quote={quote}
        />
        <BankPayment
          details={paymentState}
          updateCollectionAmount={(collectionAmount) =>
            updatePaymentState({
              collectionAmount,
            })
          }
          updateDetails={updatePaymentState}
          getError={getError}
          showValidation={showValidation}
        />
        <DeclarationSection
          hasAgreed={paymentState.hasAgreedToDeclaration}
          updateHasAgreed={(hasAgreedToDeclaration) => {
            updatePaymentState({
              hasAgreedToDeclaration,
            });
            showValidation('hasAgreedToDeclaration');
          }}
          getError={getError}
        />
        <CancellationSignpost />
        <FormFooter
          backButton={{
            onClick: () => {
              trackTextButtonClick(PageTitle.Payment, 'Back');
              navigate(quoteAndBuyRoutes.checkYourDetails);
            },
          }}
          moveNextButton={{
            text: next_button_text,
            onClick: () => trackTextButtonClick(PageTitle.Payment, 'Buy now'),
          }}
          saveButton={{
            onClick: () => {
              if (isQuoteOptionSelectionValid(quoteOptions)) {
                saveAndEmailQuote();
              }
            },
          }}
          pageTitle={PageTitle.Payment}
        />
        {savedQuoteConfirmationModal}
      </form>
    </>
  );
};

export default MonthlyPaymentPage;
